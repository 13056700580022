import { Auth0ClientOptions } from '@auth0/auth0-spa-js';

/* Variable Definitions */
export const ENV = process.env.NODE_ENV || '';
// if (!process.env.REACT_APP_PLATFORM) {
//   throw new Error('You must define an explicit platform');
// }
export const PLATFORM = process.env.REACT_APP_PLATFORM || 'development';
if (!['production', 'staging', 'development', 'testing'].includes(PLATFORM)) {
  throw new Error(`You must provide a valid PLATFORM. You provided ${PLATFORM}.`);
}
export const IS_DEV_ENV = ENV === 'development';
export const IS_DEV_PLATFORM = ['development', 'testing'].includes(PLATFORM);

/* Global Configuration */
export const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'https://testing.onarchipelago.com/api';
export const API_FILE_URL =
  process.env.REACT_APP_API_FILE_URL || API_BASE_URL.split('/api').shift();
export const IMAGE_SIZES = {
  large: {
    width: 1400,
  },
  medium: {
    width: 300,
  },
  small: {
    width: 40,
  },
};

/* Auth0 */
export const AUTH0 = (origin: string): Auth0ClientOptions => ({
  audience: 'https://archipelago-staging.auth0.com/userinfo',
  cacheLocation: 'localstorage',
  client_id: 'MUUzzKjnepByQkHZqBC0ehgJFvG20Iel',
  domain: 'auth-staging.onarchipelago.com',
  redirect_uri: `${origin}/callback`,
  scope: 'openid profile email',
  useRefreshTokens: true,
});

// Designate the environments in which to enable logging
export const LOGGING_ENABLED = ['production', 'staging', 'testing'].includes(PLATFORM);

/* Datadog */
export const DATADOG_APPLICATION_ID = '6fbed930-babc-4191-ad27-a02199df30ce';
export const DATADOG_CLIENT_ID = 'pubff1a528ca6bc5fb7f983f9134147a670';
export const DATADOG_SERVICE = 'browser';
export const DATADOG_SITE = 'datadoghq.com';

/* Google */
export const GOOGLE_RECAPTCHA = {
  SITEKEY: '6LeZyawUAAAAAOytofvxHg7lNpPiqUsKWFjn7ijJ',
};
export const GOOGLE_MAPS_API_KEY = 'AIzaSyDQfayE6aSWUvHNSOxUxgUlCHkjeFiG1to';

/* Mixpanel */
export const MIXPANEL_TOKEN = '732b9ea2bcd7c497ad459711bff88d53';
export const MIXPANEL_PROXY_URL = 'https://anp.onarchipelago.com';

export const APOLLO_TIMEOUT = 120000;

export const VERSION = process.env.GIT_SHA;

/* jobs service */
export const JOBS_CONFIG_URL =
  process.env.JOBS_CONFIG_URL || 'https://jobs.testing.onarchipelago.com/api';

/* notifications service */
export const NOTIFICATIONS_CONFIG_URL =
  process.env.NOTIFICATIONS_CONFIG_URL || 'https://notifications-testing.onarchipelago.com';

/* integrations service */
export const INTEGRATIONS_CONFIG_URL =
  process.env.REACT_APP_INTEGRATIONS_CONFIG_URL || 'https://integrations-testing.onarchipelago.com';

export const ROCKET_CHAT_URL =
  process.env.ROCKET_CHAT_URL || 'https://chat-staging.onarchipelago.com';

export const ROCKET_CHAT_DOMAIN =
  process.env.ROCKET_CHAT_DOMAIN || 'chat-staging.onarchipelago.com';

export const PRECHECK_URL =
  process.env.PRECHECK_URL || 'https://precheck-testing.onarchipelago.com';

export const COPILOT_URL =
  process.env.COPILOT_URL || 'https://chatbot-staging-alb.onarchipelago.com';
export const MAIN_APP_URL =
  PLATFORM === 'development'
    ? 'http://localhost:4200'
    : PLATFORM === 'staging'
    ? 'https://testing.onarchipelago.com'
    : 'https://platform.onarchipelago.com';

export const BUILDER_IO_KEY =
  PLATFORM === 'staging' || PLATFORM === 'development'
    ? 'c3f283831f524b6fab8bc19df8886825'
    : '6f43e1267f714b8ebdcda94694ea5fe3';
